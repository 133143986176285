// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.table-pink, .table-pink>td, .table-pink>th {
    background-color: #FF1493;
}
.table-pasion, .table-pasion>td, .table-pasion>th {
    background-color: #FF0000;
}
